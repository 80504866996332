import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { buildParams } from '../../../../core/modals/table.modal';
import { AuditAuditableItemFormDTO } from '../dto/audit-auditable-item.dto';

@Injectable({
  providedIn: 'root',
})
export class AuditAuditableItemService {
  apiUrl: string;
  private baseUrl: string = environment.apiUrl + '/audits';

  constructor(private http: HttpClient) {}

  list(
    auditId: number,
    sortField?: string,
    sortOrder?: string,
    currentPage?: number,
    pageSize?: number,
    searchQuery?: string,
    quickFilterKey?: string,
    dynamicFilters?: { [key: string]: number[] }
  ): Observable<any> {
    const params = buildParams(
      sortField,
      sortOrder,
      currentPage,
      pageSize,
      searchQuery,
      quickFilterKey,
      dynamicFilters
    );
    const apiUrl = this.baseUrl + `/${auditId}/auditable-items`;
    return this.http.get<any>(apiUrl, { params });
  }

  create(
    auditId: number,
    auditableItemId: number[]
  ): Observable<AuditAuditableItemFormDTO> {
    const url = `${this.baseUrl}/${auditId}/auditable-items`;
    return this.http.post<AuditAuditableItemFormDTO>(url, {
      auditable_item_ids: auditableItemId,
    });
  }

  get(auditId: number, auditableItemId: number): Observable<any> {
    const url = `${this.baseUrl}/${auditId}/auditable-items/${auditableItemId}`;
    return this.http.get<any>(url);
  }

  delete(auditId: number, auditableItemId: number[]) {
    const url = `${this.baseUrl}/${auditId}/auditable-items/delete`;
    return this.http.post<any>(url, { ids: auditableItemId });
  }
}
